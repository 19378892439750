<template>
  <div class="load-pick-container">
    <my-nav-bar
      title="装车拣货"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-show="showEmpty" description="暂无数据" />
      <van-cell
        v-for="(distributor, index) in distributors"
        :key="index"
        style="height: 70px"
        center
        :title="'配送员：' + distributor.name"
        :value="distributor.client_count > 0 ? distributor.client_count + '个客户待装车拣货' : ''"
        :is-link="!distributor.load_pick_id || distributor.load_pick_id === $store.state.userInfo.id"
        @click="toListView(distributor)"
      >
        <template v-if="distributor.load_pick_id" #label>
          <span>{{ `拣货人：${distributor.load_pick_name}` }}</span>
        </template>
      </van-cell>
    </div>
  </div>
</template>

<script>
import loadPickApi from '@/api/load_pick'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'LoadPickIndex',
  components: { myNavBar },
  data() {
    return {
      distributors: [],
      showEmpty: false
    }
  },
  created() {
    this.getDistributors()
  },
  methods: {
    getDistributors() {
      this.beginLoad()
      loadPickApi.distributor().then(res => {
        this.endLoad()
        this.distributors = res.data
        this.showEmpty = this.distributors.length === 0
      })
    },
    toListView(distributor) {
      if (distributor.load_pick_id && distributor.load_pick_id !== this.$store.state.userInfo.id) { return }
      this.$router.push({
        path: '/load-pick-detail',
        query: {
          delivery_id: distributor.delivery_id,
          delivery_name: distributor.name
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
