var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "load-pick-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "装车拣货",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('van-empty', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showEmpty,
      expression: "showEmpty"
    }],
    attrs: {
      "description": "暂无数据"
    }
  }), _vm._l(_vm.distributors, function (distributor, index) {
    return _c('van-cell', {
      key: index,
      staticStyle: {
        "height": "70px"
      },
      attrs: {
        "center": "",
        "title": '配送员：' + distributor.name,
        "value": distributor.client_count > 0 ? distributor.client_count + '个客户待装车拣货' : '',
        "is-link": !distributor.load_pick_id || distributor.load_pick_id === _vm.$store.state.userInfo.id
      },
      on: {
        "click": function click($event) {
          return _vm.toListView(distributor);
        }
      },
      scopedSlots: _vm._u([distributor.load_pick_id ? {
        key: "label",
        fn: function fn() {
          return [_c('span', [_vm._v(_vm._s("\u62E3\u8D27\u4EBA\uFF1A".concat(distributor.load_pick_name)))])];
        },
        proxy: true
      } : null], null, true)
    });
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }